// src\components\Products\Products.tsx
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import './Products.scss';
import defaultImage from '../../assets/images/default_image.jpg';
import { Link } from "react-router-dom";
import { getConditionalUrl } from '../../utils/cdnHelper';
import { Tooltip } from "../Tooltip";
import { ConfirmationModal } from "../ConfirmationModal";

interface Product {
  id: number;
  title: string;
  imageSrc: string;
  catChild: string;
  firebase_uid: string;
  is_active: boolean | null;
  is_deleted: boolean; // Dodaj to pole
}

interface ProductsListProps {
  catParent: string;
  catChild: string;
  onLoadingChange: (isLoading: boolean) => void;
}

export const Products: React.FC<ProductsListProps> = ({ catParent, catChild, onLoadingChange }) => {
  const { currentUser } = useAuth();
  const [ownProducts, setOwnProducts] = useState<Product[]>([]);
  const [masterProducts, setMasterProducts] = useState<Product[]>([]);
  const [loadingOwn, setLoadingOwn] = useState<boolean>(true);
  const [loadingMaster, setLoadingMaster] = useState<boolean>(true);

  const isSuperUser = currentUser?.email === 'spojrzenie@gmail.com';

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState<Product | null>(null);


  const fetchOwnProducts = useCallback(async () => {
    setLoadingOwn(true);
    try {
      const response = await axios.get(`${config.API_URL}/products/category/${catParent}/${catChild}/${currentUser?.uid}`);
      const ownProductsData = await Promise.all(response.data
        .filter((product: Product) => product.firebase_uid !== 'master')
        .map(async (product: Product) => {
          try {
            const settingsResponse = await axios.get(`${config.API_URL}/products/${currentUser?.uid}/${product.id}/settings`);
            const settings = settingsResponse.data;
            return {
              ...product,
              is_active: settings ? settings.is_active : true,
              customSellingPrice: settings ? settings.custom_selling_price : null,
              customPurchasePrice: settings ? settings.custom_purchase_price : null,
            };
          } catch (error) {
            console.error('Error fetching product settings:', error);
            return { ...product, is_active: true, customSellingPrice: null, customPurchasePrice: null };
          }
        }));
      setOwnProducts(ownProductsData);
    } catch (error) {
      console.error('Failed to fetch own products:', error);
    } finally {
      setLoadingOwn(false);
      onLoadingChange(false);
    }
  }, [catParent, catChild, currentUser, onLoadingChange]);

  const fetchMasterProducts = useCallback(async () => {
    setLoadingMaster(true);
    try {
      const response = await axios.get(`${config.API_URL}/products/category/${catParent}/${catChild}/master`);
      const fetchedMasterProducts = await Promise.all(response.data
        .map(async (product: Product) => {
          if (currentUser) {
            try {
              const settingsResponse = await axios.get(`${config.API_URL}/products/${currentUser.uid}/${product.id}/settings`);
              const settings = settingsResponse.data;
              return {
                ...product,
                is_active: settings ? settings.is_active : true,
                customSellingPrice: settings ? settings.custom_selling_price : null,
                customPurchasePrice: settings ? settings.custom_purchase_price : null,
              };
            } catch (error) {
              console.error('Error fetching product settings:', error);
              return { ...product, is_active: true, customSellingPrice: null, customPurchasePrice: null };
            }
          } else {
            return { ...product, is_active: true, customSellingPrice: null, customPurchasePrice: null };
          }
        }));
      setMasterProducts(fetchedMasterProducts);
    } catch (error) {
      console.error('Failed to fetch master products:', error);
    } finally {
      setLoadingMaster(false);
      onLoadingChange(false);
    }
  }, [catParent, catChild, currentUser, onLoadingChange]);

  const refreshProducts = useCallback(async () => {
    await fetchOwnProducts();
    await fetchMasterProducts();
  }, [fetchOwnProducts, fetchMasterProducts]);

  useEffect(() => {
    if (currentUser) {
      refreshProducts();
    }
  }, [catParent, catChild, currentUser, refreshProducts]);

  const handleToggleActivation = async (product: Product) => {
    try {
      const newActiveState = !product.is_active;
      await axios.patch(`${config.API_URL}/products/${currentUser?.uid}/${product.id}/settings`, { is_active: newActiveState });

      if (product.firebase_uid === 'master') {
        setMasterProducts(products => products.map(p => p.id === product.id ? { ...p, is_active: newActiveState } : p));
      } else {
        setOwnProducts(products => products.map(p => p.id === product.id ? { ...p, is_active: newActiveState } : p));
      }

      // Odśwież produkty po zmianie
      refreshProducts();
    } catch (error) {
      console.error(`Failed to ${product.is_active ? 'deactivate' : 'activate'} product:`, error);
    }
  };

  const openDeleteModal = (product: Product) => {
    setProductToDelete(product);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setProductToDelete(null);
  };

  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      await axios.delete(`${config.API_URL}/products/${productToDelete.id}`);
      refreshProducts();
      closeDeleteModal();
    } catch (error) {
      console.error('Failed to delete product:', error);
    }
  };

  if (!currentUser) {
    return <div>Ładuję...</div>;
  }

  return (
    <div className="content">
      {!isSuperUser ? (
        <>
          <div className="column">
            <h2>Produkty własne
              {' '}
              <Tooltip
                content="
                Tutaj wyświetlają się Twoje własne produkty, jeżeli jakieś dodałeś. Możesz je dowolnie edytować, wyłączać lub trwale usuwać z aplikacji.
                "
                iconClassName="fa-regular fa-circle-question"
              />
            </h2>
            {loadingOwn ? (
              <div className="spinner is-loading"></div>
            ) : (
              <>
                {ownProducts.length === 0 ? (
                  <>
                    <p>Brak produktów.</p>
                    <Link
                      to="/addProduct"
                      className="button m-1"
                    >
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                      <span>
                        Dodaj własny produkt
                      </span>
                    </Link>
                  </>
                ) : (
                  <div className="columns is-multiline">
                    {ownProducts.map((product) => (
                      <div key={product.id} className="column is-2-fullhd is-2-widescreen is-3-desktop is-4-tablet is-12-mobile">
                        <div className="card">
                          <div className={`productList__active ${product.is_active ? '' : 'productList__active--not'}`}>
                            <button className="productList__button" onClick={() => handleToggleActivation(product)}>
                              <span className="icon">
                                <i className="fa-solid fa-power-off"></i>
                              </span>
                              <span>
                                {product.is_active ? 'WŁĄCZONY' : 'WYŁĄCZONY'}
                              </span>
                            </button>
                          </div>
                          <div className="card-image">
                            <div
                              className="image is-2by1 productList__image"
                              style={{ backgroundImage: `url(${getConditionalUrl(product.imageSrc) || defaultImage})` }}
                            >
                            </div>
                          </div>
                          <div className="card-content">
                            <div className="content">
                              <div className="has-text-centered">
                                <span className="title is-5">{product.title}</span>
                              </div>
                              <div className="has-text-centered pt-4">
                                <Link to={`/products/${catChild}/edit/${product.id}`} className="m-1 button">
                                  <span className="icon has-text-info">
                                    <i className="fa-solid fa-pencil"></i>
                                  </span>
                                </Link>
                                <button className="m-1 button" onClick={() => openDeleteModal(product)}>
                                  <span className="icon has-text-danger">
                                    <i className="fa-solid fa-trash-can"></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="column mt-5">
            <h2>Produkty systemowe
              {' '}
              <Tooltip
                content="
                To są wbudowane w aplikację produkty. Możesz ustawić dla nich własne ceny
                lub całkowicie wyłączyć, aby nie wyświetlały się w wycenach.
                "
                iconClassName="fa-regular fa-circle-question"
              />
            </h2>
            {loadingMaster ? (
              <div className="spinner is-loading"></div>
            ) : (
              <>
                {masterProducts.length === 0 ? (
                  <p>Brak produktów.</p>
                ) : (
                  <div className="columns is-multiline">
                    {masterProducts.map((product) => (
                      <div key={product.id} className="column is-2-fullhd is-2-widescreen is-3-desktop is-4-tablet is-12-mobile">
                        <div className="card">
                          <div className={`productList__active ${product.is_active ? '' : 'productList__active--not'}`}>
                            <button className="productList__button" onClick={() => handleToggleActivation(product)}>
                              <span className="icon">
                                <i className="fa-solid fa-power-off"></i>
                              </span>
                              <span>
                                {product.is_active ? 'WŁĄCZONY' : 'WYŁĄCZONY'}
                              </span>
                            </button>
                          </div>
                          <div className="card-image">
                            <div
                              className="image is-2by1 productList__image"
                              style={{ backgroundImage: `url(${getConditionalUrl(product.imageSrc) || defaultImage})` }}
                            >
                            </div>
                          </div>
                          <div className="card-content">
                            <div className="content">
                              <div className="has-text-centered">
                                <span className="title is-5">{product.title}</span>
                              </div>
                              <div className="has-text-centered pt-4">
                                <Link to={`/products/${catChild}/edit/${product.id}`} className="m-1 button">
                                  <span className="icon has-text-info">
                                    <i className="fa-solid fa-pencil"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div className="column">
          <h2>Produkty master</h2>
          {loadingMaster ? (
            <div className="spinner is-loading"></div>
          ) : (
            <>
              {masterProducts.length === 0 ? (
                <p>Brak produktów.</p>
              ) : (
                <div className="columns is-multiline">
                  {masterProducts.map((product) => (
                    <div key={product.id} className="column is-2-fullhd is-2-widescreen is-3-desktop is-4-tablet is-12-mobile">
                      <div className="card">
                        <div className={`productList__active ${product.is_active ? '' : 'productList__active--not'}`}>
                          <button className="productList__button" onClick={() => handleToggleActivation(product)}>
                            <span className="icon">
                              <i className="fa-solid fa-power-off"></i>
                            </span>
                            <span>
                              {product.is_active ? 'WŁĄCZONY' : 'WYŁĄCZONY'}
                            </span>
                          </button>
                        </div>
                        <div className="card-image">
                          <div
                            className="image is-2by1 productList__image"
                            style={{ backgroundImage: `url(${getConditionalUrl(product.imageSrc) || defaultImage})` }}
                          >
                          </div>
                        </div>
                        <div className="card-content">
                          <div className="content">
                            <div className="has-text-centered">
                              <span className="title is-5">{product.title}</span>
                            </div>
                            <div className="has-text-centered pt-4">
                              <Link to={`/products/${catChild}/edit/${product.id}`} className="m-1 button">
                                <span className="icon has-text-info">
                                  <i className="fa-solid fa-pencil"></i>
                                </span>
                              </Link>
                              <button className="m-1 button" onClick={() => openDeleteModal(product)}>
                                <span className="icon has-text-danger">
                                  <i className="fa-solid fa-trash-can"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        message={
          <div className="content">
            <p className="has-text-weight-bold is-size-5">
              Czy na pewno chcesz usunąć
              {' '}
              <span className="has-text-info">
                {productToDelete?.title}
              </span>
              {' '}
              ?
            </p>
            <p className="has-text-danger">
              Tej operacji nie można cofnąć.
            </p>
          </div>
        }
      />
    </div>
  );
};
