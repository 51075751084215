import React from 'react';

type OrderStatus = 'not_ordered' | 'ordered' | 'in_stock';

interface OrderProductStatusProps {
  status: OrderStatus;
  onChange: (newStatus: OrderStatus) => void;
}

export const OrderProductStatus: React.FC<OrderProductStatusProps> = ({ status, onChange }) => {
  const getStatusIcon = (currentStatus: OrderStatus) => {
    switch (currentStatus) {
    case 'not_ordered':
      return <i className="fas fa-circle fa-lg"></i>;
    case 'ordered':
      return <i className="fas fa-truck fa-lg"></i>;
    case 'in_stock':
      return <i className="fas fa-box fa-lg"></i>;
    }
  };

  const getStatusColor = (currentStatus: OrderStatus) => {
    switch (currentStatus) {
    case 'not_ordered':
      return 'has-text-grey-light';
    case 'ordered':
      return 'has-text-info';
    case 'in_stock':
      return 'has-text-success';
    }
  };

  const getNextStatus = (currentStatus: OrderStatus): OrderStatus => {
    switch (currentStatus) {
    case 'not_ordered':
      return 'ordered';
    case 'ordered':
      return 'in_stock';
    case 'in_stock':
      return 'not_ordered';
    }
  };

  const statusLabels = {
    'not_ordered': 'Nie zamówione',
    'ordered': 'Zamówione',
    'in_stock': 'W magazynie'
  };

  return (
    <div className="has-text-centered">
      <button 
        className={`button is-light ${getStatusColor(status)}`}
        onClick={() => onChange(getNextStatus(status))}
        title="Kliknij aby zmienić status"
      >
        <span className="icon">
          {getStatusIcon(status)}
        </span>
        <span className="has-text-weight-medium">{statusLabels[status]}</span>
      </button>
    </div>
  );
};
