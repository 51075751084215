import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './EstimateFurniture.scss';
import config from '../../config';
import { useAuth } from '../../context/AuthContext';
import { EstimateList } from '../../components/EstimateList';
import { useNavigate } from 'react-router-dom';
import { EstimateSearch } from '../../components/EstimateSearch/EstimateSearch';
import { Helmet } from "react-helmet";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Estimate } from '../../types/Estimate';

interface RawEstimate {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: number;
  hasOrder: number;
  orderId?: number;
}

interface ApiParams {
  page: number;
  limit: number;
  search?: string;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
}

export const EstimateFurniture: React.FC = () => {
  const [estimates, setEstimates] = useState<Estimate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [estimateToDelete, setEstimateToDelete] = useState<number | null>(null);
  const ESTIMATES_PER_PAGE = 20;

  useEffect(() => {
    const params: ApiParams = {
      page: currentPage,
      limit: ESTIMATES_PER_PAGE,
      search: searchTerm,
      sortBy,
      sortOrder
    };

    const loadEstimates = async () => {
      if (!currentUser) return;

      try {
        if (currentPage === 1) {
          setIsLoading(true);
        }

        const response = await axios.get<{ estimates: RawEstimate[], hasMore: boolean }>(
          `${config.API_URL}/estimates/user/${currentUser.uid}`,
          { params }
        );

        if (response.data.estimates) {
          const updatedEstimates = response.data.estimates.map(estimate => ({
            ...estimate,
            isLocked: Boolean(estimate.isLocked),
            hasOrder: Boolean(estimate.orderId),
            orderId: estimate.orderId || undefined
          }));

          setEstimates(prev =>
            currentPage === 1 ? updatedEstimates : [...prev, ...updatedEstimates]
          );
          setHasMore(response.data.hasMore);
        }
      } catch (error) {
        console.error('Error loading estimates:', error);
        toast.error('Nie udało się załadować wycen. Spróbuj ponownie później.');
      } finally {
        setIsLoading(false);
      }
    };

    loadEstimates();
  }, [currentUser, currentPage, searchTerm, sortBy, sortOrder]);

  useEffect(() => {
    setCurrentPage(1);
    setEstimates([]); // Czyścimy listę przy zmianie kryteriów wyszukiwania/sortowania
  }, [searchTerm, sortBy, sortOrder]);

  const handleDeleteEstimate = (id: number) => {
    setEstimateToDelete(id);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (estimateToDelete === null) return;

    try {
      await axios.delete(`${config.API_URL}/estimates/${estimateToDelete}`, {
        headers: {
          Authorization: `Bearer ${currentUser?.getIdToken()}`
        }
      });

      setEstimates(prevEstimates =>
        prevEstimates.filter(estimate => estimate.id !== estimateToDelete)
      );
      toast.success('Wycena została usunięta pomyślnie.');
    } catch (error) {
      console.error('Error deleting estimate:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Nie udało się usunąć wyceny. Spróbuj ponownie później.');
        }
      } else {
        toast.error('Wystąpił nieoczekiwany błąd.');
      }
    } finally {
      setIsModalOpen(false);
      setEstimateToDelete(null);
    }
  };

  const handleCloneEstimate = async (id: number) => {
    try {
      const response = await axios.post<{ id: number }>(`${config.API_URL}/estimates/${id}/clone`);
      const clonedEstimateId = response.data.id;

      console.log(clonedEstimateId);

      // Odśwież listę wycen
      setCurrentPage(1);
      const response2 = await axios.get<{ estimates: RawEstimate[], hasMore: boolean }>(
        `${config.API_URL}/estimates/user/${currentUser?.uid}`,
        {
          params: {
            page: 1,
            limit: 20,
            search: searchTerm,
            sortBy,
            sortOrder
          }
        }
      );

      if (response2.data.estimates) {
        const updatedEstimates = response2.data.estimates.map(estimate => ({
          ...estimate,
          isLocked: Boolean(estimate.isLocked),
          hasOrder: Boolean(estimate.orderId),
          orderId: estimate.orderId || undefined
        }));
        setEstimates(updatedEstimates);
        setHasMore(response2.data.hasMore);
      }

      toast.success('Wycena została skopiowana pomyślnie.');
    } catch (error) {
      console.error('Error cloning estimate:', error);
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message || 'Nie udało się sklonować wyceny. Spróbuj ponownie później.');
      } else {
        toast.error('Wystąpił nieoczekiwany błąd.');
      }
    }
  };

  const loadMore = useCallback(async () => {
    if (!isLoading && !isLoadingMore && hasMore) {
      try {
        setIsLoadingMore(true);
        setCurrentPage(prev => prev + 1);
      } catch (error) {
        console.error('Error loading more estimates:', error);
        toast.error('Nie udało się załadować więcej wycen');
      } finally {
        setIsLoadingMore(false);
      }
    }
  }, [isLoading, isLoadingMore, hasMore]);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleSortChange = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      // Jeśli klikamy w tę samą kolumnę, zmieniamy kierunek sortowania
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortBy(newSortBy);
      // Dla dat (created_at, updated_at) domyślnie sortujemy DESC (od najnowszych)
      setSortOrder(newSortBy.includes('_at') ? 'DESC' : 'ASC');
    }
  };

  const handleToggleLock = async (id: number) => {
    try {
      const response = await axios.put(`${config.API_URL}/estimates/${id}/toggle-lock`, null, {
        headers: {
          Authorization: `Bearer ${currentUser?.getIdToken()}`
        }
      });
      if (response.data.success) {
        setEstimates(prevEstimates =>
          prevEstimates.map(estimate =>
            estimate.id === id ? { ...estimate, isLocked: response.data.isLocked } : estimate
          )
        );
        toast.success(
          response.data.isLocked ? 'Wycena została zablokowana.' : 'Wycena została odblokowana.'
        );
      }
    } catch (error) {
      console.error('Error toggling estimate lock:', error);
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message || 'Nie udało się zmienić stanu blokady wyceny.');
      } else {
        toast.error('Wystąpił nieoczekiwany błąd.');
      }
    }
  };

  return (
    <div className="main">
      <Helmet>
        <title>Lista wycen | GoEstima</title>
        <meta name="description" content="Kalkulator mebli na wymiar" />
        <link rel="canonical" href="https://goestima.pl/estimate-furniture" />
      </Helmet>
      <div className="column">
        <EstimateSearch searchTerm={searchTerm} onSearch={handleSearch} />
      </div>
      <>
        {isLoading && estimates.length === 0 && (
          <div className="has-text-centered my-6">
            <span className="icon is-large">
              <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </span>
          </div>
        )}

        <EstimateList
          estimates={estimates}
          onEdit={(estimate) => navigate(`/estimate-furniture/${estimate.id}`)}
          onDelete={handleDeleteEstimate}
          onClone={handleCloneEstimate}
          onSort={handleSortChange}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onToggleLock={handleToggleLock}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          hasMore={hasMore}
          onLoadMore={loadMore}
        />
      </>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        message={
          <div className="content">
            <p className="has-text-weight-bold is-size-5">
              Czy na pewno chcesz usunąć tę wycenę?
            </p>
            <p className="has-text-danger">
              Tej operacji nie można cofnąć.
            </p>
          </div>
        }
      />
    </div>
  );
};
