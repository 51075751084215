import React from 'react';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: React.ReactNode;  // Zmień typ z string na React.ReactNode
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head has-text-centered">
          <p className="modal-card-title">
            <span className="icon-text">
              <span className="icon">
                <i className="fa-regular fa-circle-check"></i>
              </span>
              <span>Potwierdź</span>
            </span>
          </p>
        </header>
        <section className="modal-card-body has-text-centered">
          <p>{message}</p>
        </section>
        <footer className="modal-card-foot is-justify-content-center">
          <div className="buttons">
            <button className="button is-danger" onClick={onConfirm}>Usuń</button>
            <button className="button" onClick={onClose}>Anuluj</button>
          </div>
        </footer>
      </div>
    </div>
  );
};
