// DisplaySingleProductView.tsx
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldArrayWithId } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './DisplaySingleProduct.scss';
import defaultImage from '../../assets/images/default_image.jpg';
import { getConditionalUrl } from '../../utils/cdnHelper';
import { Tooltip } from "../Tooltip";

interface Option {
  id: number;
  option_name: string;
}

interface WatchedItem {
  id: string;
  quantity: number;
  width: number | null;
  height: number | null;
  option_id: number | null;
}

interface DisplaySingleProductViewProps {
  title: string;
  desc: string;
  unit: string;
  imageSrc: string;
  formId: string;
  fields: FieldArrayWithId<{ items: WatchedItem[] }, "items", "id">[];
  options: Option[];
  watchedItems: WatchedItem[];
  selectedOption: number | null;
  totalPrice: number;
  isEditModeEnabled: boolean;
  handleOptionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleFieldChange: (index: number, field: string, value: string) => void; // zmiana z number na string
  decrementQuantity: (index: number) => void;
  incrementQuantity: (index: number) => void;
  handleRemoveItem: (index: number, itemId: string | undefined) => void;
  handleCreateEmptyProduct: () => void;
  isDeleted: boolean;
  onRemoveProduct: () => void;
  isArchived: boolean;
  isActive: boolean;
  customSellingPrice: string | null;
  customPurchasePrice: string | null;
  isLocked: boolean;
  category: string;
  widthAndHeight?: number;
  estimateId: number;
  id: string;
  totalArea: number;
}

export const DisplaySingleProductView: React.FC<DisplaySingleProductViewProps> = ({
  title,
  desc,
  unit,
  imageSrc,
  formId,
  fields,
  options,
  watchedItems,
  selectedOption,
  totalPrice,
  isEditModeEnabled,
  handleOptionChange,
  handleFieldChange,
  decrementQuantity,
  incrementQuantity,
  handleRemoveItem,
  handleCreateEmptyProduct,
  isActive,
  isDeleted,
  isArchived,
  isLocked,
  category,
  widthAndHeight,
  estimateId,
  id,
  totalArea,
}) => {
  const { t } = useTranslation();
  const conditionalImageSrc = getConditionalUrl(imageSrc);

  const widthInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const heightInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleKeyDown = (index: number, field: 'width' | 'height') => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = e.currentTarget.value.replace(',', '.');
      const numValue = parseFloat(value);

      if (!isNaN(numValue)) {
        handleFieldChange(index, field, value);

        if (field === 'width') {
          heightInputRefs.current[index]?.focus();
        } else if (field === 'height') {
          e.currentTarget.blur();
        }
      }
    }
  };

  const shouldShowArea = useMemo(() => {
    return category === 'korpusy' && widthAndHeight === 1;
  }, [category, widthAndHeight]);

  const saveAreaToLocalStorage = useCallback((area: number) => {
    if (category === 'korpusy' && widthAndHeight === 1 && estimateId) {
      const key = `area.${estimateId}.${id}`; // Zmiana tutaj: używamy kropki zamiast myślnika
      const areaValue = area.toFixed(2);
      localStorage.setItem(key, areaValue);
      console.log(`Saved area to localStorage: key=${key}, value=${areaValue}, category=${category}, id=${id}`);
    } else {
      console.log(`Not saving to localStorage: category=${category}, widthAndHeight=${widthAndHeight}, estimateId=${estimateId}, id=${id}`);
    }
  }, [category, widthAndHeight, estimateId, id]);

  useEffect(() => {
    if (shouldShowArea) {
      saveAreaToLocalStorage(totalArea);
    }
  }, [totalArea, shouldShowArea, saveAreaToLocalStorage]);

  return (
    <div className={`cardStandard p-5 ${isArchived ? 'is-archived' : ''} ${!isActive ? 'is-inactive' : ''}`}>
      <div className="card">
        <div className="card-image">
          <div
            className="image is-2by1 cardStandard__image"
            style={{ backgroundImage: `url(${conditionalImageSrc || defaultImage})` }}
          >
            {fields.length !== 0 && (
              <span className="icon is-medium has-text-white cardStandard__image--checked">
                <i className="fas fa-check"></i>
              </span>
            )}
          </div>
        </div>
        <div className="card-content">
          <div className="block">
            <div className={`has-text-centered`}>
              <p className={`title is-5 ${fields.length !== 0 ? 'has-text-info' : ''}`}>{title}</p>
            </div>
          </div>
          {options.length > 0 && watchedItems.length > 0 && (
            <div className="block has-text-centered">
              <div className="select">
                <select
                  value={selectedOption || ''}
                  onChange={handleOptionChange}
                  disabled={isArchived || isLocked}
                >
                  <option value="0">{selectedOption === null ? 'Opcje' : 'Bez opcji'}</option>
                  {options.map(option => (
                    <option key={option.id} value={option.id}>{option.option_name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="content">
            <form onSubmit={handleFormSubmit}>
              {fields.map((field, index) => (
                <div key={field.id} className="block">
                  {!widthAndHeight ? (
                    <div className="columns is-variable is-2 is-vcentered is-mobile">
                      {!isLocked && (
                        <div className="column is-narrow">
                          <a onClick={() => decrementQuantity(index)} className={isArchived ? 'is-disabled' : ''}>
                            <span className="icon is-small">
                              <i className="fa-solid fa-minus"></i>
                            </span>
                          </a>
                        </div>
                      )}
                      <div className="column">
                        <input
                          type="text"
                          className="input"
                          placeholder="Ilość"
                          value={watchedItems[index]?.quantity?.toString().replace('.', ',') || ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === '' || /^[0-9]*[.,]?[0-9]*$/.test(value)) {
                              handleFieldChange(index, 'quantity', value);
                            }
                          }}
                          onBlur={(e) => {
                            const value = e.target.value.replace(',', '.');
                            const numValue = parseFloat(value);
                            if (!isNaN(numValue)) {
                              handleFieldChange(index, 'quantity', value);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              const value = e.currentTarget.value.replace(',', '.');
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleFieldChange(index, 'quantity', value);
                                e.currentTarget.blur();
                              }
                            }
                          }}
                          disabled={isArchived}
                        />
                      </div>
                      {!isLocked && (
                        <div className="column is-narrow">
                          <a onClick={() => incrementQuantity(index)} className={isArchived ? 'is-disabled' : ''}>
                            <span className="icon is-small">
                              <i className="fa-solid fa-plus"></i>
                            </span>
                          </a>
                        </div>
                      )}
                      <div className="column is-narrow">
                        {unit}
                      </div>
                      <div className="column is-narrow">
                        {!isLocked && (
                          <button
                            type="button"
                            className="button is-danger is-small"
                            onClick={() => handleRemoveItem(index, watchedItems[index]?.id)}
                          // disabled={isArchived}
                          >
                            <span className="icon is-small">
                              <i className="fa-regular fa-trash-can"></i>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <input
                      type="hidden"
                      value="1"
                    />
                  )}
                  {widthAndHeight === 1 && (
                    <div className="columns is-vcentered is-mobile">
                      <div className="column">
                        <div className="content">
                          <div className="columns is-variable is-2 is-vcentered is-mobile custom-columns-margin">
                            <div className="column">
                              <input
                                type="text"
                                className="input"
                                placeholder="Szerokość"
                                value={watchedItems[index]?.width?.toString().replace('.', ',') || ''}
                                onChange={(e) => {
                                  // Pozwalamy na cyfry, jeden przecinek/kropkę i zero na początku
                                  const value = e.target.value;
                                  if (value === '' || /^[0-9]*[.,]?[0-9]*$/.test(value)) {
                                    handleFieldChange(index, 'width', value);
                                  }
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value.replace(',', '.');
                                  const numValue = parseFloat(value);
                                  if (!isNaN(numValue)) {
                                    handleFieldChange(index, 'width', value);
                                  }
                                }}
                                onKeyDown={handleKeyDown(index, 'width')}
                                ref={el => widthInputRefs.current[index] = el}
                                disabled={isArchived}
                              />
                            </div>
                            <div className="column is-narrow">
                              {t('form-cm')}
                            </div>
                          </div>
                          <div className="columns is-variable is-2 is-vcentered is-mobile">
                            <div className="column">
                              <input
                                type="text"
                                className="input"
                                placeholder="Wysokość"
                                value={watchedItems[index]?.height?.toString().replace('.', ',') || ''}
                                onChange={(e) => {
                                  // Pozwalamy na cyfry, jeden przecinek/kropkę i zero na początku
                                  const value = e.target.value;
                                  if (value === '' || /^[0-9]*[.,]?[0-9]*$/.test(value)) {
                                    handleFieldChange(index, 'height', value);
                                  }
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value.replace(',', '.');
                                  const numValue = parseFloat(value);
                                  if (!isNaN(numValue)) {
                                    handleFieldChange(index, 'height', value);
                                  }
                                }}
                                onKeyDown={handleKeyDown(index, 'height')}
                                ref={el => heightInputRefs.current[index] = el}
                                disabled={isArchived}
                              />
                            </div>
                            <div className="column is-narrow">
                              {t('form-cm')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-narrow">
                        <button
                          type="button"
                          className="button is-danger"
                          onClick={() => handleRemoveItem(index, watchedItems[index]?.id)}
                        // disabled={isArchived}
                        >
                          <span className="icon is-small">
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {fields.length === 0 && (
                <div className="form__description-product block">
                  <span className="is-size-6 has-text-grey">
                    {desc}
                  </span>
                </div>
              )}
              {!isArchived && !isLocked && (
                <>
                  <div className="block has-text-centered">
                    <button
                      type="button"
                      onClick={handleCreateEmptyProduct}
                      className={`button ${watchedItems.length === 0 ? 'is-info' : ''}`}
                    >
                      <span>
                        {watchedItems.length > 0 ? 'następny' : 'dodaj'}
                      </span>
                      <span className="icon">
                        <i className="fas fa-add"></i>
                      </span>
                    </button>
                    {watchedItems.length > 0 && (
                      <span className="pl-2">
                        <Tooltip
                          content="Ten sam produkt możesz dodać wiele razy."
                          iconClassName="fa-regular fa-circle-question"
                        />
                      </span>
                    )}
                  </div>
                </>
              )}
              {watchedItems.length > 0 && (
                <div className="block form__total">
                  {shouldShowArea && (
                    <div>
                      <span className="icon">
                        <i className="fa-regular fa-square"></i>
                      </span>
                      {' '} Powierzchnia: {totalArea.toFixed(2)} m<sup>2</sup>
                    </div>
                  )}
                  <div>
                    <span className="icon">
                      <i className="fas fa-coins"></i>
                    </span>
                    {' '} Razem: {totalPrice.toFixed(2)} zł
                  </div>
                </div>
              )}
            </form>
            {Boolean(isDeleted) && (
              <div className="notification is-warning mt-4 p-3 has-text-centered">
                Produkt archiwalny. Kliknij ikonę
                <span className="icon">
                  <i className="fa-solid fa-trash-can"></i>
                </span>
                aby trwale usunąć go z wyceny.
              </div>
            )}

            {!isActive && !isDeleted && (
              <div className="notification is-info mt-4 p-3 has-text-centered">
                Ten produkt jest wyłączony, ale jest wyświetlany bo został użyty w tej wycenie.
              </div>
            )}
            {isEditModeEnabled && (
              <div className="has-text-centered pt-3">
                <hr className="dropdown-divider" />
                <div className="pt-2">
                  <Link to={`/products/${category}/edit/${formId}`} className="button">
                    <span className="icon has-text-info">
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplaySingleProductView;