import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RecentEstimatesList.scss'; // Upewnij się, że utworzysz ten plik dla stylów
import { Tooltip } from "../Tooltip";

interface Estimate {
  id: number;
  name: string;
  updated_at: string;
}

interface RecentEstimatesListProps {
  estimates: Estimate[];
}

export const RecentEstimatesList: React.FC<RecentEstimatesListProps> = ({ estimates }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('pl-PL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const truncateText = (text: string, maxLength = 50): string => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleRowClick = (estimateId: number) => {
    navigate(`/estimate-furniture/${estimateId}`);
  };

  return (
    <div className="content">
      <Link
        to="/create-estimate"
        className="button is-primary"
      >
        <span className="icon">
          <i className="fa-solid fa-folder-plus"></i>
        </span>
        <span>
          Nowa wycena
        </span>
      </Link>
      <h2 className="title is-4 mb-3">
        Najnowsze wyceny
        {' '}
        <Tooltip
          content="Wyświetlane od ostatnio aktualizowanej wyceny. Kliknij przycisk WSZYSTKIE WYCENY, aby zarządzać wycenami."
          iconClassName="fa-regular fa-circle-question"
        />
      </h2>
      {estimates.length === 0 ? (
        <>
          <p>Trochę tu pusto...</p>
          <p>Jeżeli to Twoja pierwsza wizyta, zapraszamy do dzialu
            {' '}
            <Link to="/help" className="has-text-weight-bold">
              Pomocy
            </Link>.
          </p>
          <p>
            W rożnych miejscach aplikacji wyświetlają się takie ikony
            <span className="icon">
              <i className="fa-regular fa-circle-question"></i>
            </span>, są to podpowiedzi. Wystarczy najechać na nie kursorem myszki, lub nacisnąć na telefonie.
            Podpowiedzi możesz wyłączyć w
            {' '}
            <Link to="/account" className="has-text-weight-bold">
              Ustawieniach
            </Link>.
          </p>
        </>
      ) : (
        <div className="table-container mb-3">
          <table className="table is-striped is-hoverable ">
            <tbody>
              {estimates.map((estimate) => (
                <tr
                  key={estimate.id}
                  onClick={() => handleRowClick(estimate.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td className="is-vcentered" style={{ width: '5%' }}>
                    <span className="icon is-size-4 folder-icon-container">
                      <i className="fa-regular fa-folder folder-icon folder-closed"></i>
                      <i className="fa-regular fa-folder-open folder-icon folder-open has-text-primary"></i>
                    </span>
                  </td>
                  <td className="is-vcentered">
                    <span className="has-text-weight-semibold">
                      {truncateText(estimate.name, 60)}
                    </span>
                  </td>
                  <td className="is-vcentered has-text-right">
                    <span className="is-size-7 has-text-grey">
                      {formatDate(estimate.updated_at)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="content pt-4">
        <Link
          to="/estimate-furniture"
          className="button"
        >
          <span className="icon">
            <i className="fa-regular fa-folder"></i>
          </span>
          <span>
            Lista wycen
          </span>
        </Link>
      </div>
    </div>
  );
};
