import { t } from "i18next";
import { LoginWithGoogle } from "../../components/LoginWithGoogle";
import { RegisterForm } from "../../components/RegisterForm";
import { useAuth } from '../../context/AuthContext';
import "./RegisterPage.scss";
import { Logo } from "../../components/Header/Logo";
import { ResendVerificationEmailLink } from "../../components/ResendVerificationEmailLink";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const RegisterPage = () => {
  const { currentUser, emailVerified, logout } = useAuth();

  return (
    <div className="registerPage">
      <Helmet>
        <title>Darmowe konto w aplikacji do wycen mebli na wymiar | GoEstima</title>
        <meta name="description" content="Darmowe konto w aplikacji kalkulator mebli kuchennych i nie tylko | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/register" />
      </Helmet>
      <section className="loginPage__content">
        <div className="box">
          <div className="has-text-centered pt-5">
            <Logo />
          </div>
          {!currentUser && (
            <>
              <div className="pb-5 column has-text-centered registerPage__welcome">
                <p className="title is-4">Darmowa rejestracja</p>
                <p className="subtitle is-5">bez zobowiązań</p>
              </div>
              <div className="column">
                <LoginWithGoogle title="Rejestracja przez Google" />
              </div>
              <div>
                <div className="divider"> lub </div>
              </div>
              <div className="column">
                <RegisterForm />
              </div>
              <div className="is-size-7 has-text-centered pt-3">
                <span className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </span>
                Rejestrując się akceptujesz <Link to="/terms-of-service">Regulamin</Link> i <Link to="/privacy-policy">Politykę Prywatności</Link>.
              </div>
            </>
          )}

          {currentUser && emailVerified && (
            <>
              <div className="has-text-centered column">Jesteś zalogowany.</div>
              <div className="has-text-centered column">
                <button title="Wyloguj się" onClick={logout} className="button is-primary">
                  Wyloguj się
                </button>
              </div>
            </>
          )}

          {currentUser && !emailVerified && (
            <>
              <div className="has-text-centered column has-text-danger pt-6">Zweryfikuj swój adres e-mail.</div>
              <div className="has-text-centered column has-text-weight-medium">Wysłaliśmy e-mail z linkiem do aktywacji konta. Odbierz pocztę i kliknij w otrzymany link aktywacyjny.</div>
              <ResendVerificationEmailLink />
            </>
          )}
        </div>
        <div className="has-text-centered content is-small has-text-white">
          <span className="icon">
            <i className="fa-regular fa-copyright"></i>
          </span>
          2024 - {new Date().getFullYear()} Janiewicz&apos;s
          <br />
          Krzysztof Janiewicz
          <br />
          {t('footer-all-rights-reserved')}
        </div>
      </section>
    </div>
  );
};
