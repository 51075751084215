import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import axios from 'axios';
import config from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useEditMode } from '../../context/EditModeContext';
import DisplaySingleProductView from './DisplaySingleProductView';
import { debounce } from 'lodash';

interface FormProps {
  id: string;  // Zmień to na string
  estimateId: number;
  title: string;
  description: string;
  unit: string;
  imageSrc: string;
  sellingPrice: number;
  widthAndHeight?: number;
  purchasePrice: number;
  option_id?: number | null;
  is_deleted: boolean;
  onRemoveProduct: (productId: string) => void;
  onProductUpdate: () => void;
  // Dodaj inne pola, które są wymagane
  isArchived?: boolean;
  isActive: boolean;
  customSellingPrice?: string | null;
  customPurchasePrice?: string | null;
  isLocked: boolean;
  category: string;
}

interface Option {
  id: number;
  option_name: string;
  selling_price: number;
  purchase_price: number;
}

interface FetchedItem {
  id: string;
  quantity: number;
  width: number | null;
  height: number | null;
  option_id: number | null;
  [key: string]: string | number | null | undefined;
}

interface FormValues {
  items: FetchedItem[];
}

type FieldType = 'quantity' | 'width' | 'height' | string;

interface ItemData {
  item: FetchedItem;
  field: FieldType;
  value: number | null; // to zostawiamy jako number, bo do API wysyłamy już przekonwertowaną wartość
  index: number;
}

const DisplaySingleProductContainer: React.FC<FormProps> = ({
  id,
  estimateId,
  title,
  description,
  unit,
  imageSrc,
  sellingPrice,
  widthAndHeight,
  purchasePrice,
  option_id,
  isActive,
  is_deleted,
  onProductUpdate,
  onRemoveProduct,
  isArchived = false,
  customSellingPrice: initialCustomSellingPrice,
  customPurchasePrice: initialCustomPurchasePrice,
  isLocked,
  category,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(option_id || null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const { isEditModeEnabled } = useEditMode();
  const { currentUser } = useAuth();

  const { control, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      items: [{ id: '', quantity: 0, width: null, height: null, option_id: null }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [customSellingPrice, setCustomSellingPrice] = useState<string | null>(initialCustomSellingPrice || null);
  const [customPurchasePrice, setCustomPurchasePrice] = useState<string | null>(initialCustomPurchasePrice || null); const watchedItems = watch('items');

  const debouncedApiUpdateRef = useRef(
    debounce(async (
      itemData: ItemData,
      getSelectedOptionPriceCallback: typeof getSelectedOptionPrice
    ) => {
      const { item, field, value } = itemData;

      try {
        const { selling } = getSelectedOptionPriceCallback(item.option_id);
        let newTotalPrice = 0;

        if (widthAndHeight) {
          const newWidth = field === 'width' ? (value || 0) : (item.width || 0);
          const newHeight = field === 'height' ? (value || 0) : (item.height || 0);
          if (newWidth > 0 && newHeight > 0) {
            newTotalPrice = selling * (newWidth * newHeight) / 10000;
          }
        } else {
          newTotalPrice = (field === 'quantity' ? (value || 0) : (item.quantity || 0)) * selling;
        }

        const payload = {
          id: item.id,
          estimate_id: estimateId,
          product_id: Number(id),
          quantity: widthAndHeight ? 1 : (field === 'quantity' ? value : item.quantity),
          width: field === 'width' ? value : item.width,
          height: field === 'height' ? value : item.height,
          total_price: newTotalPrice,
          purchase_price: getSelectedOptionPriceCallback(item.option_id).purchase,
          selling_price: selling,
          option_id: item.option_id,
        };

        const response = await axios.put(`${config.API_URL}/estimate-products/${item.id}`, payload);
        const updatedItem = response.data;
        console.log('Received updated item:', updatedItem);
        onProductUpdate();
      } catch (error) {
        console.error(`Error updating field ${field}:`, error);
      }
    }, 500)
  );

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/estimate-products/${estimateId}/product/${id}`);
        const fetchedItems = response.data;

        if (fetchedItems.length > 0) {
          setValue('items', fetchedItems.map((item: FetchedItem) => ({
            id: item.id,
            quantity: Number(item.quantity),
            width: item.width,
            height: item.height,
            option_id: item.option_id
          })));
        } else {
          setValue('items', []);
        }

        // Pobierz customowe ceny
        const settingsResponse = await axios.get(`${config.API_URL}/products/${currentUser?.uid}/${id}/settings`);
        if (settingsResponse.data) {
          setCustomSellingPrice(settingsResponse.data.custom_selling_price);
          setCustomPurchasePrice(settingsResponse.data.custom_purchase_price);
        }

        // Pobierz opcje produktu
        const optionsResponse = await axios.get(`${config.API_URL}/products/${id}/options/${currentUser?.uid}`);
        setOptions(optionsResponse.data);

      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [estimateId, id, setValue, currentUser]);
  const getSelectedOptionPrice = useCallback((optionId: number | null = selectedOption) => {
    if (optionId === null) {
      return {
        selling: parseFloat(customSellingPrice || sellingPrice?.toString() || '0'),
        purchase: parseFloat(customPurchasePrice || purchasePrice?.toString() || '0'),
      };
    }
    const selected = options.find(option => option.id === optionId);
    return selected
      ? { selling: selected.selling_price, purchase: selected.purchase_price }
      : {
        selling: parseFloat(customSellingPrice || sellingPrice?.toString() || '0'),
        purchase: parseFloat(customPurchasePrice || purchasePrice?.toString() || '0')
      };
  }, [selectedOption, options, sellingPrice, purchasePrice, customSellingPrice, customPurchasePrice]);

  const calculateItemPrice = useCallback((item: FetchedItem, sellingPrice: number) => {
    if (widthAndHeight) {
      return (item.width && item.height) ? sellingPrice * (item.width * item.height) / 10000 : 0;
    } else {
      return (item.quantity || 0) * sellingPrice;
    }
  }, [widthAndHeight]);

  const calculateTotalPrice = useCallback((items: FetchedItem[]) => {
    return items.reduce((total, item) => {
      const { selling } = getSelectedOptionPrice(item.option_id);
      return total + calculateItemPrice(item, selling);
    }, 0);
  }, [getSelectedOptionPrice, calculateItemPrice]);

  const calculateArea = useCallback((item: FetchedItem) => {
    if (widthAndHeight) {
      return (item.width && item.height) ? (item.width * item.height) / 10000 : 0;
    } else {
      return 0;
    }
  }, [widthAndHeight]);

  const calculateTotalArea = useCallback((items: FetchedItem[]) => {
    return items.reduce((total, item) => {
      return total + calculateArea(item);
    }, 0);
  }, [calculateArea]);

  const handleFieldChange = useCallback(async (index: number, field: string, value: string) => {
    if (isLocked) return;
    const item = watchedItems[index];
    console.log('Updating item:', { index, field, value, item });
  
    if (!item || !item.id) {
      console.error('Item or item ID is undefined');
      return;
    }
  
    // Aktualizuj UI natychmiast z surową wartością string
    setValue(`items.${index}.${field}`, value);
  
    // Konwertuj na number tylko dla API i obliczeń
    const numValue = value !== '' ? parseFloat(value.replace(',', '.')) : null;
  
    // Przygotuj zaktualizowany item do przeliczenia totali
    const updatedItem = {
      ...item,
      [field]: value // zachowuj string w stanie
    };
  
    const updatedItems = [...watchedItems];
    updatedItems[index] = updatedItem;
  
    // Dla totali używaj przekonwertowanej wartości
    if (typeof numValue === 'number' && !isNaN(numValue)) {
      const transformedItems = updatedItems.map(item => {
        const fieldValue = item[field];
        let convertedValue = 0; // domyślna wartość
  
        if (fieldValue === value && typeof numValue === 'number') {
          convertedValue = numValue;
        } else if (typeof fieldValue === 'string' && fieldValue !== '') {
          const parsed = parseFloat(fieldValue.replace(',', '.'));
          convertedValue = !isNaN(parsed) ? parsed : 0;
        } else if (typeof fieldValue === 'number') {
          convertedValue = fieldValue;
        }
  
        return {
          ...item,
          [field]: convertedValue
        };
      });
  
      setTotalPrice(calculateTotalPrice(transformedItems));
      setTotalArea(calculateTotalArea(transformedItems));
  
      // Wywołaj debounced update do API
      debouncedApiUpdateRef.current(
        { 
          item: {...updatedItem, [field]: numValue}, 
          field, 
          value: numValue, 
          index 
        }, 
        getSelectedOptionPrice
      );
    }
  
  }, [isLocked, watchedItems, setValue, calculateTotalPrice, calculateTotalArea, getSelectedOptionPrice]);

  const handleOptionChange = useCallback(async (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (isLocked) return;
    const optionId = parseInt(event.target.value, 10);
    console.log('Option selected:', optionId);
    setSelectedOption(optionId === 0 ? null : optionId);

    const { selling, purchase } = getSelectedOptionPrice(optionId === 0 ? null : optionId);

    const updatedItems = watchedItems.map(item => {
      const newQuantity = item.quantity || 0;
      const newWidth = item.width || null;
      const newHeight = item.height || null;
      const newTotalPrice = calculateItemPrice({ ...item, quantity: newQuantity, width: newWidth, height: newHeight }, selling);

      return {
        id: item.id,
        estimate_id: estimateId,
        product_id: Number(id),
        quantity: newQuantity,
        width: newWidth,
        height: newHeight,
        option_id: optionId === 0 ? null : optionId,
        selling_price: selling,
        purchase_price: purchase,
        total_price: newTotalPrice
      };
    });

    try {
      await Promise.all(updatedItems.map(item =>
        axios.put(`${config.API_URL}/estimate-products/${item.id}`, item)
      ));

      updatedItems.forEach((item, index) => {
        setValue(`items.${index}`, item);
      });

      setTotalPrice(calculateTotalPrice(updatedItems));
      onProductUpdate();

      console.log('Options updated for all items');
      onProductUpdate();
    } catch (error) {
      console.error('Error updating product options:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Server response:', error.response.data);
      }
    }
  }, [watchedItems, getSelectedOptionPrice, setValue, calculateItemPrice, calculateTotalPrice, estimateId, id, onProductUpdate, isLocked]);

  const handleCreateEmptyProduct = useCallback(async () => {
    if (isLocked) return; // Zmiana tutaj: return jeśli wycena jest zablokowana
    try {
      const { selling, purchase } = getSelectedOptionPrice();
      const payload = {
        estimate_id: estimateId,
        product_id: id,
        selling_price: selling,
        purchase_price: purchase,
        option_id: selectedOption,
        quantity: widthAndHeight ? 1 : 0,
        width: null,
        height: null,
        total_price: 0
      };

      console.log('Creating empty product with:', payload);

      const response = await axios.post(`${config.API_URL}/estimate-products/create-empty`, payload);
      const newItemId = response.data.id;

      console.log('Created empty product with ID:', newItemId);

      const newItem = {
        id: newItemId,
        quantity: widthAndHeight ? 1 : 0,
        width: null,
        height: null,
        option_id: selectedOption,
        selling_price: selling,
        purchase_price: purchase,
        total_price: 0
      };

      console.log('New item created:', newItem);
      append(newItem);

      setTotalPrice(calculateTotalPrice([...watchedItems, newItem]));
      onProductUpdate();
    } catch (error) {
      console.error('Error creating empty product:', error);
    }
  }, [getSelectedOptionPrice, estimateId, id, selectedOption, append, widthAndHeight, watchedItems, calculateTotalPrice, onProductUpdate, isLocked]);

  const incrementQuantity = useCallback(async (index: number) => {
    if (!widthAndHeight) {
      const currentValue = Number(watchedItems[index]?.quantity) || 0;
      const newValue = currentValue + 1;
      await handleFieldChange(index, 'quantity', newValue.toString());
    }
  }, [watchedItems, handleFieldChange, widthAndHeight]);
  
  const decrementQuantity = useCallback((index: number) => {
    if (!widthAndHeight) {
      const currentValue = Number(watchedItems[index]?.quantity) || 0;
      if (currentValue > 0) {
        handleFieldChange(index, 'quantity', (currentValue - 1).toString());
      }
    }
  }, [watchedItems, handleFieldChange, widthAndHeight]);  

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(watchedItems));
    setTotalArea(calculateTotalArea(watchedItems));
  }, [watchedItems, calculateTotalPrice, calculateTotalArea]);

  const handleRemoveItem = useCallback(async (index: number, itemId: string | undefined) => {
    if (isLocked) return;
    console.log('Removing item:', { index, itemId });
    console.log('Current items:', watchedItems);

    if (itemId) {
      try {
        await axios.delete(`${config.API_URL}/estimate-products/${itemId}`, {
          data: { estimateId } // Dodaj estimateId do ciała żądania
        });
        remove(index);
        console.log('Removed product with ID:', itemId);
        setTotalPrice(calculateTotalPrice(watchedItems.filter((_, i) => i !== index)));
        setTotalArea(calculateTotalArea(watchedItems.filter((_, i) => i !== index)));
        onProductUpdate();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    } else {
      console.error('Item ID is undefined');
      console.log('Item at index:', watchedItems[index]);
      remove(index);
    }
  }, [watchedItems, remove, calculateTotalPrice, calculateTotalArea, onProductUpdate, estimateId, isLocked]);

  return (
    <DisplaySingleProductView
      title={title}
      desc={description} // Użyj 'description' zamiast 'desc'
      unit={unit}
      imageSrc={imageSrc}
      formId={id.toString()}
      fields={fields}
      options={options}
      watchedItems={watchedItems}
      selectedOption={selectedOption}
      widthAndHeight={widthAndHeight}
      totalArea={totalArea}
      totalPrice={totalPrice}
      isEditModeEnabled={isEditModeEnabled}
      handleOptionChange={handleOptionChange}
      handleFieldChange={handleFieldChange}
      decrementQuantity={decrementQuantity}
      incrementQuantity={incrementQuantity}
      handleRemoveItem={handleRemoveItem}
      handleCreateEmptyProduct={handleCreateEmptyProduct}
      isActive={isActive}
      isDeleted={is_deleted}
      onRemoveProduct={() => onRemoveProduct(id.toString())}
      isArchived={isArchived}
      customSellingPrice={customSellingPrice}
      customPurchasePrice={customPurchasePrice}
      isLocked={isLocked}
      category={category}
      id={id}
      estimateId={estimateId}
    />
  );
};

export default DisplaySingleProductContainer;
