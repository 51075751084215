import React, { useState } from 'react';

interface OrderProductNotesProps {
  notes: string | null;
  onChange: (notes: string) => void;
}

export const OrderProductNotes: React.FC<OrderProductNotesProps> = ({ notes, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedNotes, setEditedNotes] = useState(notes || '');

  const handleSave = () => {
    onChange(editedNotes);
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setEditedNotes(notes || '');
    setIsModalOpen(false);
  };

  const modalContent = (
    <div className="field">
      <div className="control">
        <textarea
          className="textarea"
          value={editedNotes}
          onChange={(e) => setEditedNotes(e.target.value)}
          placeholder="Wpisz notatkę..."
          rows={4}
          autoFocus
        />
      </div>
    </div>
  );

  const modalFooter = (
    <div className="buttons is-centered">
      <button className="button is-primary" onClick={handleSave}>
        <span className="icon">
          <i className="fas fa-save"></i>
        </span>
        <span>Zapisz</span>
      </button>
      <button className="button" onClick={handleClose}>
        <span className="icon">
          <i className="fas fa-times"></i>
        </span>
        <span>Anuluj</span>
      </button>
    </div>
  );

  return (
    <>
      <div 
        className="notes-cell"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="notes-content">
          {notes ? (
            <span className="notes-text">{notes}</span>
          ) : (
            <span className="has-text-grey-light">Brak notatek</span>
          )}
        </div>
        <button 
          className="button"
          title="Edytuj notatkę"
        >
          <span className="icon">
            <i className="fas fa-edit"></i>
          </span>
        </button>
      </div>

      <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={handleClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <span className="icon-text">
                <span className="icon">
                  <i className="fas fa-edit"></i>
                </span>
                <span>Edycja notatki</span>
              </span>
            </p>
          </header>
          <section className="modal-card-body">
            {modalContent}
          </section>
          <footer className="modal-card-foot">
            {modalFooter}
          </footer>
        </div>
      </div>
    </>
  );
};
